import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BulkAssetImportComponent } from '@components/bulk-asset-import/bulk-asset-import.component';
import { ScanResultComponent } from '@components/scan-result/scan-result.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  showBulkImportDialog(params, width: string, maxHeight: string) {
    return this.dialog
      .open(BulkAssetImportComponent, {
        data: {
          fieldsCheckDup: params.fieldsCheckDup,
        },
        maxWidth: width,
        maxHeight,
        disableClose: true,
        closeOnNavigation: true
      })
      .afterClosed();
  }

  showScanResultDialog(params, width: string, maxHeight: string) {
    return this.dialog
      .open(ScanResultComponent, {
        data: {
          errors: params.errors
        },
        width,
        maxHeight,
        disableClose: true,
        closeOnNavigation: true
      })
      .afterClosed();
  }
}
